import React, { useState } from 'react';
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import ProjectCard from '../Cards/ProjectCard';
import { Fade } from 'react-reveal';

const projects = {
  'web': [
    {
      title: 'Among Us Icons  ',
      link: "https://among-us-icons.vercel.app/",
      repo: "https://github.com/dicka88/among-us",
      isExternal: true,
      cover: "../../../images/projects/among-us-icons-cover.png",
    },
    {
      title: 'Resort Restaurant',
      link: "https://resort-resto.vercel.app",
      repo: "https://github.com/dicka88/resort",
      isExternal: true,
      cover: "../../../images/projects/resort-cover.png",
    },
    {
      title: 'Propil',
      link: "https://propil.vercel.app",
      repo: "https://github.com/dicka88/propil",
      isExternal: true,
      cover: "../../../images/projects/propil-cover.png",
    },
    {
      title: 'Hello API',
      link: "https://helloapi.vercel.app",
      repo: "https://github.com/dicka88/helloapi",
      isExternal: true,
      cover: "../../../images/projects/helloapi-cover.png",
    },
    {
      title: 'NEFA',
      link: "https://nefa.netlify.app",
      repo: "https://github.com/dicka88/nefa",
      isExternal: true,
      cover: "../../../images/projects/nefa-cover.png",
    },
    {
      title: 'Todo Daily',
      link: "https://tododaily.vercel.app",
      repo: "https://github.com/dicka88/tododaily",
      isExternal: true,
      cover: "../../../images/projects/todo-daily-cover.png",
    },
    {
      title: 'Dicka Ismaji Personal Website',
      link: "https://dickaismaji.com",
      repo: null,
      isExternal: true,
      cover: "../../../images/projects/dickaismaji-cover.png",
    }, {
      title: 'Pantau Covid',
      link: "https://pantaucovid.netlify.app",
      repo: "https://github.com/dicka88/pantaucovid",
      isExternal: true,
      cover: '../../../images/projects/pantau-covid-cover.png',
    }, {
      title: 'SCSS Compiler',
      link: "https://scsscompiler.herokuapp.com",
      repo: "https://github.com/dicka88/scsscompiler",
      isExternal: true,
      cover: '../../../images/projects/scsscompiler-cover.png',
    }, {
      title: 'Tokopedia Web Slicing',
      link: "https://tokped.vercel.app",
      repo: "https://github.com/dicka88/tokped",
      isExternal: true,
      cover: '../../../images/projects/tokopedia-cover.png',
    }, {
      title: 'Chatyuk',
      link: "https://chatyukkuy.herokuapp.com",
      repo: "https://github.com/dicka88/chatyuk",
      isExternal: true,
      cover: '../../../images/projects/chatyuk-cover.png',
    }, {
      title: 'CryptoGalaxy Web Slicing',
      link: "https://cryptogalaxy.netlify.app",
      repo: "https://github.com/dicka88/nefa",
      isExternal: true,
      cover: '../../../images/projects/cryptogalaxy-cover.png',
    },
  ],
  'design': [
    {
      title: 'Animala',
      link: '/projects/animala',
      isExternal: false,
      cover: '../../../images/projects/animala-design-cover.png',
    }, {
      title: 'Self Grow',
      link: '/projects/selfgrow',
      isExternal: false,
      cover: '../../../images/projects/selfgrow-design-cover.png',
    }, {
      title: 'Yubloo',
      link: '/projects/yubloo',
      isExternal: false,
      cover: '../../../images/projects/yubloo-design-cover.png',
    }, {
      title: 'Chatia',
      link: '/projects/chatia',
      isExternal: false,
      cover: '../../../images/projects/chatia-design-cover.png',
    }, {
      title: 'Dicka Ismaji Personal Website',
      link: '/projects/dickaismaji',
      isExternal: false,
      cover: '../../../images/projects/dickaismaji-cover.png',
    }
  ],
  'mobile': [
    {
      title: 'Chatia | Flutter',
      link: 'https://github.com/dicka88/chatia',
      repo: 'https://github.com/dicka88/chatia',
      isExternal: true,
      cover: '../../../images/projects/chatia-design-cover.png',
    }, {
      title: 'Gojek | React Native',
      link: 'https://github.com/dicka88/gojek-react-native',
      repo: 'https://github.com/dicka88/gojek-react-native',
      isExternal: true,
      cover: '../../../images/projects/gojek-react-native-cover.png',
    }, {
      title: 'Github User Searcher | Kotlin',
      link: 'https://github.com/dicka88/github-searcher',
      repo: 'https://github.com/dicka88/github-searcher',
      isExternal: true,
      cover: '../../../images/projects/github-searcher-cover.png',
    }
  ],
};

export default function PortfolioSection({ showCount = null }) {
  const [tab, setTab] = useState(0);

  return (
    <section className="pb-2">
      <h2 className="font-bold">PORTFOLIO</h2>
      <div className="mb-4">
        <SecondaryButton className={`py-1 inline-block mr-4 ${tab !== 0 ? 'border-none' : ''}`} onClick={() => setTab(0)}>
          Web
        </SecondaryButton>
        <SecondaryButton className={`py-1 inline-block mr-4 ${tab !== 1 ? 'border-none' : ''}`} onClick={() => setTab(1)}>
          Design
        </SecondaryButton>
        <SecondaryButton className={`py-1 inline-block mr-4 ${tab !== 2 ? 'border-none' : ''}`} onClick={() => setTab(2)}>
          Mobile
        </SecondaryButton>
      </div>
      <div>
        <div className={tab === 0 ? 'block' : 'hidden'}>
          <section className="pb-4">
            <h3 className="font-bold sub-title mb-2">Personal Project</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 pl-4">
              {projects.web.map((item, index) =>
                <Fade key={index} bottom delay={index * 100}>
                  <ProjectCard key={index} item={item} />
                </Fade>
              )}
              {projects.length === 0 &&
                <div className="text-center py-12">
                  Nothing
                </div>
              }
            </div>
          </section>
          {/* <section className="pb-4">
            <h3 className="font-bold sub-title mb-2">Real Project</h3>
            <div className="pl-4">
              <h3>Toko Quick Official Website</h3>
              <p className="font-thin">
                Tokoquick.id is Offical Store that selling Tractor and sparepart with join venture KUBOTA Company.
                My jobs is slicing design into web page, integrating OTP system, make invoice of email, optimizing
                SEO and loadspeed page use caching CDN provider to make images compressed and be small size. Build using
                PHP Framework and also do small configuration at server using VPS and make it run successfull.
              </p>
            </div>
          </section> */}
        </div>
        <div className={tab === 1 ? 'block' : 'hidden'}>
          <h3 className="font-bold sub-title">Personal Project</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 pl-4">
            {projects.design.map((item, index) =>
              <Fade key={index} bottom delay={index * 100}>
                <ProjectCard key={index} item={item} />
              </Fade>
            )}
            {projects.length === 0 &&
              <div className="text-center py-12">
                Nothing
              </div>
            }
          </div>
        </div>
        <div className={tab === 2 ? 'block' : 'hidden'}>
          <h3 className="font-bold sub-title">Personal Project</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 pl-4">
            {projects.mobile.map((item, index) =>
              <Fade key={index} bottom delay={index * 100}>
                <ProjectCard key={index} item={item} />
              </Fade>
            )}
            {projects.length === 0 &&
              <div className="text-center py-12">
                Nothing
              </div>
            }
          </div>
        </div>
      </div >
    </section >
  );
}